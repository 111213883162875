<!--  -->
<template>
  <div class="inner">
    <div class="footer">
      <div class="main layout-flex-all isPc">
        <div class="left">
          <img src="@/assets/img/footer_logo.png" alt />
          <div class="msg">
            0536-8184803
            <span>官方联系电话</span>
          </div>
          <div class="other">
            <img src="@/assets/img/footer_wx.png" alt />
            <img src="@/assets/img/footer_email.png" alt />
          </div>
        </div>
        <div class="right">
          <div class="column layout-flex-all">
            <dl>
              <dt>新手入门</dt>
              <dd>
                <router-link to>购买流程</router-link>
              </dd>
              <dd>
                <router-link to>服务商协议</router-link>
              </dd>
              <dd>
                <router-link to>用户协议</router-link>
              </dd>
              <dd>
                <router-link to>投诉与退款</router-link>
              </dd>
            </dl>
            <dl>
              <dt>用户操作</dt>
              <dd>
                <router-link to>购买协议</router-link>
              </dd>
              <dd>
                <router-link to>服务商协议</router-link>
              </dd>
              <dd>
                <router-link to>用户协议</router-link>
              </dd>
              <dd>
                <router-link to>投诉与退款</router-link>
              </dd>
            </dl>
            <dl>
              <dt>新手入门</dt>
              <dd>
                <router-link to>购买流程</router-link>
              </dd>
              <dd>
                <router-link to>购买流程</router-link>
              </dd>
              <dd>
                <router-link to>购买流程</router-link>
              </dd>
              <dd>
                <router-link to>购买流程</router-link>
              </dd>
            </dl>
            <dl>
              <dt>新手入门</dt>
              <dd>
                <router-link to>购买流程</router-link>
              </dd>
              <dd>
                <router-link to>购买流程</router-link>
              </dd>
              <dd>
                <router-link to>购买流程</router-link>
              </dd>
              <dd>
                <router-link to>购买流程</router-link>
              </dd>
            </dl>
            <dl>
              <dt>新手入门</dt>
              <dd>
                <router-link to>购买流程</router-link>
              </dd>
              <dd>
                <router-link to>购买流程</router-link>
              </dd>
              <dd>
                <router-link to>购买流程</router-link>
              </dd>
              <dd>
                <router-link to>购买流程</router-link>
              </dd>
            </dl>
          </div>
        </div>
      </div>
      <div class="link layout-flex-all isPc">
        <div class="txt">
          <span>友情链接：</span>
        </div>
        <div class="txt" v-for="(item,index) in 8" :key="index">华创智慧云</div>
      </div>
      <div class="copyRight">Copyright 2014-2020 © 华创科技. All rights reserved.</div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    };
  },

  components: {},

  computed: {},

  methods: {}
}

</script>
<style lang='scss' scoped>
.inner {
  padding: 50px 0 0;
  width: 100%;
  color: #fff;
  background: #37405e;
  .footer {
    max-width: 1200px;
    margin: 0 auto;
    .main {
      align-items: flex-start;
      .left {
        img {
          margin-bottom: 20px;
        }
        .msg {
          margin-bottom: 20px;
          font-size: 22px;
          line-height: 1.4;
          span {
            color: #afb3bf;
            font-size: 15px;
            display: block;
          }
        }
        .other {
          img {
            width: 48px;
            height: 48px;
            margin-right: 15px;
          }
        }
      }
      .right {
        padding-right: 40px;
        width: calc(100% - 300px);
        .column {
          dl {
            dt {
              margin-bottom: 30px;
            }
            dd {
              font-size: 15px;
              margin: 0 0 10px;
              cursor: pointer;
              &:hover {
                opacity: 0.85;
              }
              a {
                color: #acb0bc;
              }
            }
          }
        }
      }
    }
    .link {
      display: flex;
      padding: 0 60px;
      width: 100%;
      height: 50px;
      border-top: 1px solid #5b637b;
      border-bottom: 1px solid #5b637b;
      box-sizing: border-box;
      .txt {
        font-size: 14px;
        color: #a6aab7;
        cursor: pointer;
        &:hover {
          opacity: 0.85;
        }
        span {
          color: #fff;
        }
      }
    }
    .copyRight {
      width: 100%;
      padding: 30px 0;
      text-align: center;
      font-size: 14px;
      color: #a6aab7;
    }
  }
}
@media screen and(max-width:996px) {
  .inner {
    padding-top: 0;
  }
}
</style>