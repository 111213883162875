<!--  -->
<template>
  <div class="pheader" :style="fixed?'position: fixed;box-shadow: 0px 0px 5px 1px #eee':''">
    <div class="inner layout-flex-all">
      <div class="left layout-flex">
        <h1 class="logo layout-flex" @click="$router.push('/')">
          <img src="@/assets/logo.png" />
        </h1>
        <div class="name">
          <div class="api layout-flex">
            <img src="@/assets/img/api.png" alt />
            <span>分发平台</span>
          </div>
          <p>数据开放，技术共享</p>
        </div>
      </div>
      <div class="center isPc">
        <div class="inputBox layout-flex">
          <el-autocomplete
            class="inline-input"
            v-model="state"
            :fetch-suggestions="querySearch"
            placeholder="请输入您要寻找的产品或服务"
            @select="handleSelect"
          ></el-autocomplete>
          <btn type="primary" buttonname="搜索" :buttonstyle="style" class="searchBtn"></btn>
        </div>
        <div class="sup layout-flex">
          <div :class="['item',{orange:index<3}]" v-for="(item,index) in sup" :key="index">{{item}}</div>
        </div>
      </div>
      <div class="right">
        <btn
          type="primary"
          buttonname="登录"
          :buttonstyle="style"
          @click="toLogin"
          v-if="display"
        ></btn>
        <div v-else class="member layout-flex">
          <div class="word">帮助文档</div>
          <div class="console" @click="link('dashboard')">控制台</div>
          <el-popover placement="bottom" width="160" trigger="hover" popper-class="header-popper">
            <div class="info">
              <div class="list" @click="link('user/info')">账号信息</div>
              <div class="list" @click="link('user/set')">安全设置</div>
              <div class="list" @click="link('api')">我的接口</div>
              <div class="list" @click="link('order')">我的订单</div>
              <div class="out" @click="signOut">退出</div>
            </div>
            <div class="user" slot="reference">
              MiNi
              <i class="iconfont pdxxia1"></i>
            </div>
          </el-popover>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {base64encodeUrlSafe} from "@/common/index.js"
export default {
  props:{
    fixed:{
      type:Boolean,
        default:false
    }
  },
  data () {
    return {
      style: {
        'width': "89px",
        'height': "36px",
        'font-size': '15px',
        'border-radius': "20px",
      },
      sup: ["全国快递", "天气", "股票", '短信', '银行卡', '违章', '身份证', '手机号实名', '条码', '二维码', '手机归属地'],
      state: "",
      serarchList: [
        { "value": "热门APi" },
        { "value": "大厂接口" },
        { "value": "人工智能" },
        { "value": "虚拟充值" },
        { "value": "生活服务" },
        { "value": "交通地理" },
        { "value": "IT技术" },
        { "value": "企业管理" },
        { "value": "金融商业" },
      ],
      display: true
    };
  },

  components: {},

  computed: {},
  mounted () {
    if (this.$store.state.token) {
      this.display = false
    } else {
      this.display = true
    }
  },
  methods: {
    toLogin() {
      const originUrl = base64encodeUrlSafe("https://frame.icuapi.com/#/")
      // jump('login')
      window.location.href=
          "http://localhost:8888/" +
          (originUrl? "?originUrl=" + originUrl : "");
    },
    signOut () {
      localStorage.clear()
      this.jump('')
      location.reload()
    },
    querySearch (queryString, cb) {
      var serarchList = this.serarchList;
      var results = queryString ? serarchList.filter(this.createFilter(queryString)) : serarchList;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    handleSelect () { },
    jump (path) {
      console.log(path);
      this.$router.push('/' + path)
    },
    link (path) {
      console.log(path);
      window.location = "http://192.168.0.115:8080/#/" + path + "?url=192.168.0.115&port=9001"
    }
  }
}

</script>
<style lang="scss">
.header-popper {
  padding: 10px 0 0;
  .info {
    .list {
      padding: 10px 15px;
      cursor: pointer;
      &:hover {
        color: #0a0a0a;
        background: #f5f5f5;
      }
    }
    .out {
      padding: 10px 20px;
      border-top: 1px solid #eee;
      cursor: pointer;
    }
  }
}
</style>
<style lang='scss' scoped>
.pheader {
  padding: 20px 0;
  width: 100%;
  top:0;
  left:0;
  background: #fff;
  .inner {
    max-width: 1200px;
    margin: 0 auto;
    align-items: flex-start;
    .left {
      .logo {
        padding-right: 7px;
        cursor: pointer;
        margin: 0;
      }
      .name {
        border-left: 2px solid #e1e1e1;
        padding-left: 7px;
        .api {
          margin-bottom: 5px;
          span {
            margin-left: 4px;
            color: #082f56;
          }
        }
        p {
          font-size: 14px;
          color: #9d9d9d;
        }
      }
    }
    .center {
      padding: 0 20px;
      // transform: translateX(-60px);
      .inputBox {
        margin-bottom: 10px;
        padding: 0 15px;
        max-width: 560px;
        height: 36px;
        border: 1px solid #e7eaed;
        border-radius: 40px;
        position: relative;
        & ::v-deep .el-autocomplete {
          height: 34px;
          font-size: 14px;
          width: calc(100% - 89px);
        }
        & ::v-deep .el-input__inner {
          border: none;
          height: 34px;
        }
        .searchBtn {
          position: absolute;
          right: 0;
          top: 0;
        }
      }
      .sup {
        .item {
          padding-left: 7px;
          font-size: 14px;
          color: #9d9d9d;
          cursor: pointer;
        }
        .orange {
          color: #ff6a00;
        }
      }
    }
    .right {
      .member {
        .word,
        .console {
          padding: 8px;
          margin-right: 20px;
          color: #666666;
          cursor: pointer;
          font-size: 15px;
          &:hover {
            color: #2468f2;
            background: #f8f8f8;
          }
        }
        .user {
          display: flex;
          align-items: flex-end;
          font-size: 15px;
          color: #666;
          cursor: pointer;
          &:hover {
            color: #2468f2;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 996px) {
  .pheader {
    .inner {
      .left {
        .logo {
          img {
            width: 80%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 560px) {
  .pheader {
    padding: 8px 0;
    background: #f5f5f5;
    .inner {
      padding: 0 2px;
      align-items: center;
      .left {
        .logo {
          img {
            width: 50%;
          }
        }
        .name {
          display: none;
        }
      }
    }
  }
}
</style>
